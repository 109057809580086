import * as React from 'react';
import Box from '@mui/material/Box';
import NavBar from '../NavBar/NavBar';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { TakeSurveyBtn, Title } from '../../components';
import { colors } from '../../config/constants';

import Para from '../../components/Para';

export default function Header() {
    const theme = useTheme();
    const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));
    let data = {
        heading: "The quickest way to gather and showcase feedback.", para:
            "Ekommo makes it easy for businesses to collect and share customer feedback.Choose a template, send a link, and get instant feedback.It’s perfect for building trust and improving your products or services."
    }
    return (
        <Box
            sx={{
                // backgroundImage: `url(${featureImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 'auto',
                width: '100%',
            }}
        >
            <NavBar />
            <Grid container display="flex" justifyContent={"center"} flexDirection={{ xs: 'column', md: 'row' }} sx={{ p: 5, marginTop: 5 }}>
                <Grid item xs={11} md={11} sx={{ textAlign: "center", marginTop: isSmallerThanMd ? 5 : 10 }}>
                    <Title text={data.heading} color={colors.titleColor} align="center" customStyle={{
                        fontWeight: "bold"
                    }} />
                    <Para text="( Launching Soon )" color={colors.primary} align="center" customStyle={{ fontStyle: 'italic', display: "block" }} />
                    <Para text={data.para} color={colors.paraColor} align="center" customStyle={{ display: "block", marginTop: 15 }} />
                    {/* <Para text="Are you a small or mid-sized business managing your store locally and handling everything through WhatsApp or social media? Spend just 5 minutes completing this survey and become one of our early sign-up users." color={colors.paraColor} align="left" customStyle={{ display: "block" }} /> */}
                    <TakeSurveyBtn variant={colors.primary} colorText="#fff" />
                </Grid>
                {/* <Grid item xs={12} md={6} sx={{ p: 0.5, marginTop: { xs: 5, md: 10 } }}>
                    <img src={headerImg} alt='header' style={{ height: "auto", width: "100%" }} />
                </Grid> */}
            </Grid>
        </Box >
    );
}