import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Grid, Slider, Button } from '@mui/material';
import Para from './Para';
import Title from './Title';
import TakeSurveyBtn from './TakeSurveyBtn';
import { colors } from '../config/constants';

export default function Pricing({ icon, text }) {
    const [reviewsValues, setReviewsValues] = React.useState('')
    const [amount, setAmount] = React.useState('')
    const features = ['Gather reviews via email and SMS.', 'Gain basic insights into customer satisfaction.', 'Collect feedback directly from social media and WhatsApp.',]

    function valuetext(value) {
        if (value == 0) {
            setAmount('0')
            setReviewsValues('60')
        }
        if (value == 10) {
            setAmount('8')
            setReviewsValues('90')
        }
        if (value == 20) {
            setAmount('20')
            setReviewsValues('120')
        }
        if (value == 30) {
            setAmount('40')
            setReviewsValues('Unlimited')
        }
        return `${value}°C`;
    }

    return (
        <Grid sx={{
            height: { xs: 'auto', md: 'auto' },
            width: '100%',
            borderRadius: '10px',
            border: '0.5px solid rgba(255, 255, 255, 0.16)',
            background: colors.white,

            padding: '30px',
            boxShadow: `0px 8px 16px #edf8f5`,

            display: 'flex',
            flexDirection: 'column',
            alignSelf: "center", margin: "auto",
            justifyContent: "space-between"
        }}>
            <Box sx={{

                height: 'auto ',
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                marginTop: 3



            }}>

                <Para text={`${reviewsValues} Reviews`} color={colors.paraColor} align="left" customStyle={{
                    fontWeight:
                        "400",


                }} />


                <div>

                    <Para text={`$ ${amount}`} color={colors.primary} align="left" customStyle={{
                        fontWeight:
                            "800",


                    }} />
                    <Para text={`/Month`} color={colors.paraColor} align="left" customStyle={{
                        fontWeight:
                            "400"
                    }} />
                </div>



            </Box>
            <Grid>

                <Slider
                    aria-label="Pricing"
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="off"
                    // shiftStep={30}
                    step={10}
                    marks
                    min={0}
                    max={30}

                    style={{ color: colors.primary, }}
                    size='large'


                />


                <Divider />
                <p>Our Pricing Plans Include All Features of Ekommo</p>
                <Button style={{ marginTop: 10, height: 50, width: 200, fontWeight: "bold", color: "#fff", backgroundColor: colors.primary, borderRadius: '10px', fontSize: "1.8vh" }}>
                    Try It Now

                </Button>


            </Grid>

        </Grid >
    );
}