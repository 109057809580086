import * as React from 'react';
import Box from '@mui/material/Box';

import { Grid } from '@mui/material';
import { HowItWorksItem, Pricing, Title } from '../../components';
import Para from '../../components/Para';
import { colors } from '../../config/constants';
export default function PricingSection() {
    return (
        <Box
            sx={{
                // backgroundImage: `url(${featureImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 'auto',
                width: '100%',
            }}
        >
            <Grid container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", marginTop: 10 }}>
                <Title text="Simple, review-based pricing" color={colors.titleColor} align="center" />
                <Para text="No credit card required." color={colors.primary} align="center" customStyle={{ fontStyle: 'italic', display: "block" }} />

                <Grid container item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignContent: "center", p: 1, }}>
                    <Grid item>
                        <Pricing />
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    );
}